import { getIronSession } from 'iron-session';
import useMutation from 'libs/useMutation';
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react';
import Head from "next/head";
import { signIn } from 'next-auth/react';
import { useSession } from "next-auth/react"

const Login: NextPage = () => {
    const { data: session, status } = useSession()
    const router = useRouter()
    const requestInfo = async(id:string) => {
        const result = await (await fetch("/api/valid?id=" + id)).json()
        const uk = result.uk;
        if (uk) {
            window.location.href = "/enter?key=" + uk;
        }
    }
    useEffect(()=>{
        if( status === "authenticated") {
            const id = session?.user.id;
            // skey에서 uk를 가져온다
            if ( id !== "" ) {
                requestInfo(id)
            }
        }
    },[status])
    return <>
        <Head>
            <title>{`쑥켓 - 로그인`}</title>
        </Head>
        <div className="w-screen md:bg-[#fff] overflow-x-hidden">
            <div className="w-full relative md:w-[400px] md:left-1/2 md:-translate-x-1/2 pb-10">
                <br/><br/>
                <div onClick={()=>{
                    signIn("kakao")
                }}>
                    <img src="/assets/kakao_login.png" />
                </div>
            </div>
        </div>
    </>;
}

export default Login;